import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import DragIndicator from "@material-ui/icons/DragIndicator";
import Select from "react-select";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Area,
  Tooltip,
} from "recharts";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import Aux from "hoc/auxiliar";
import PermissionsGate from "components/PermissionsGate";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import useStyles from "./styles";
import MultiSelect from "components/MultiSelect";
import { toast } from "react-toastify";
import { getVehiclesByOperation } from "services/vehicle";
import { orderVehiclesByCrescent } from "helpers/vehicles";
import { typeVizualizationOptions } from "domain/selectOptions";
import { format } from "date-fns";
import { extractIds } from "helpers/extractIds";
import { useDebouncedCallback } from "use-debounce";
import { useAppSelector } from "redux/store";
import { objectComparisonCallback } from "helpers/objectComparisonCallback";
import { getFleetEvolutive, postFleetEvolutive } from "services/evolutive";
import Infobox from "components/Infobox";
import EvolutiveSkeleton from "components/Skeletons/EvolutiveSkeleton";
import { translationsMonths } from "helpers/translationMonths";
import { colourStyles } from "./colourStyles";
import { TitlePage } from "components/TitlePage";
import { Today } from "@material-ui/icons";
import { useWindowSize } from "../../../hooks/useWindowsSize";
import { handleAmplitudeEvent } from "services/amplitude";

export default function Evolutive() {
  const classes = useStyles();

  registerLocale("pt-BR", ptBR);

  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [typeVizualizationSelected, setTypeVizualizationSelected] = useState(
    typeVizualizationOptions[0],
  );
  const [charts, setCharts] = useState([]);
  const [finalResult, setFinalResult] = useState([]);
  const { currentCustomer } = useAppSelector((state) => state.global.user);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  async function onChange(_, sourceIndex, targetIndex) {
    const result = swap(charts, sourceIndex, targetIndex);

    let model = [];

    result.forEach(function (element, index) {
      model.push({
        id: element.id,
        order: index + 1,
        title: element.title,
        customer_id: Number(currentCustomer),
      });
      element.order = index + 1;
    });
    setFinalResult(result);
    const response = await postFleetEvolutive(model);
    if (response.status !== 200) {
      fetchData(selectedVehicles, startDate, typeVizualizationSelected);
    }
  }

  const fetchData = useCallback(
    async (vehiclesSelected, date, dateType) => {
      const data = {
        ids: extractIds(vehiclesSelected),
        startDate: format(date, "yyyy-MM-dd'T'00:00:00-0300"),
        dateType: dateType.visualization,
      };
      const formattedDate = format(date, "MMM/yyyy")
      setLoading(true);
      try {
        const response = await getFleetEvolutive(
          data.ids,
          data.startDate,
          data.dateType,
          currentCustomer,
        );
        if (response.status === 200) {
          const orderResponse = response.data.data.sort((a, b) =>
            objectComparisonCallback(a.order, b.order),
          );
          if (dateType.value === 3) {
            const translatedOrderResponse = orderResponse.map((item) => ({
              ...item,
              evolutive: item.evolutive.map((evo) => ({
                ...evo,
                name: translationsMonths[evo.name],
              })),
            }));
            setFinalResult(translatedOrderResponse)
          } else {
            setFinalResult(orderResponse)
          }
        }
        handleAmplitudeEvent('Evolutive Loaded', { total_vehicle: vehiclesSelected.length, group: data.dateType, period: formattedDate })
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [currentCustomer],
  );

  const fetchVehicles = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer);
      if (response.data.customers) {
        let orderedVehiclesByCustomer = response.data.customers.sort(
          (a, b) => (a.name > b.name) - (a.name < b.name)
        );

        orderedVehiclesByCustomer.map((option, i) => {
          const optionVehicles = option.vehicles
            .filter(item => item?.devicesHistory !== null)
            .filter(item => item?.currentDevice?.identification !== '');

          orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
        });
        setVehicles(orderedVehiclesByCustomer);
      }
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer]);

  const handleSelectedData = (selected) => {
    handleAmplitudeEvent('Filter Updated', { label: "Frota", value: selected.map(item => item?.identification + ' - ' + item?.plate).join(", ") })
    setSelectedVehicles(selected);
  };

  const handleSelectDate = (date) => {
    const formattedDate = format(date, "MMM/yyyy")
    handleAmplitudeEvent('Filter Updated', { label: "Periodo", value: formattedDate })
    setStartDate(date);
  };

  useEffect(() => {
    currentCustomer && fetchVehicles();
  }, [currentCustomer, fetchVehicles]);

  const debounced = useDebouncedCallback(
    (selectedVehicles, startDate, typeVizualizationSelected) => {
      fetchData(selectedVehicles, startDate, typeVizualizationSelected);
    },
    500,
  );

  const toLowerCase = (title) => title.toLowerCase();

  const getDataCyTag = (title) => {
    const tags = {
      "nota geral": "h4TituloNotaGeral",
      "média consumida(km/l)": "h4TituloMediaConsumidaKmL",
      "litros consumidos": "h4TituloLitrosConsumidos",
      "início da faixa verde": "h4TituloInicioFaixaVerde",
      "piloto automático": "h4TituloPilotoAutomático",
      "aproveitamento de embalo": "h4TituloAproveitamentoEmbalo",
      "total de km rodados": "h4TituloTotalKmRodados",
      "acelerando acima do verde": "h4TituloAcelerandoAcimaVerde",
      "motor ligado parado": "h4TituloMotorLigadoParado",
      "excesso de velocidade": "h4TituloExcessoVelocidade",
    };

    return tags[toLowerCase(title)] || "h4Titulo";
  }


  const boxDataCy = (title, prefix = "div") => {
    const formattedTitle = title
      .toLowerCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/\b\w/g, (match) => match.toUpperCase())
      .replace(/ /g, "")
    return `${prefix}${formattedTitle}Dados`;
  };

  useEffect(() => {
    if (typeVizualizationSelected && startDate && selectedVehicles.length) {
      debounced(selectedVehicles, startDate, typeVizualizationSelected);
    }
    if (!selectedVehicles.length) {
      setCharts([]);
    }
  }, [startDate, selectedVehicles, typeVizualizationSelected, debounced]);

  const size = useWindowSize();

  useEffect(() => {
    const modifiedResult = finalResult.map(item => {
      return {
        ...item,
        title: capitalizeFirstLetter(item.title)?.replace('Inicio', 'Início')
      };
    });
    setCharts(modifiedResult)
  }, [finalResult])

  useEffect(() => {
    handleAmplitudeEvent('Evolutive Screen Viewed')
  }, [])

  return (
    <Aux>
      <PermissionsGate scopes={["can_view_evolutive"]}>
        <Grid container>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: 4, marginTop: 10 }}
            >
              <TitlePage title={"Evolutivo"} />
            </Grid>
            <Grid
              item
              xl={size.grid4}
              lg={size.grid4}
              md={size.grid4}
              sm={size.grid4}
              xs={size.grid4}
            >
              <Paper elevation={0} className={classes.multiselect}>
                <MultiSelect
                  listData={vehicles}
                  handleSelectedListData={handleSelectedData}
                  selectedData={selectedVehicles}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xl={size.grid4}
              lg={size.grid4}
              md={size.grid4}
              sm={size.grid4}
              xs={size.grid4}
              data-cy="divSelectDataCorte"
            >
              <Paper elevation={0} className={classes.paper}>
                <Grid container>
                  <Grid item xs={1} style={{ alignSelf: "center" }}>
                    <Today />
                  </Grid>
                  <Grid item xs={11}>
                    <Select
                      styles={colourStyles}
                      placeholder="Tipo de visualização"
                      value={typeVizualizationSelected}
                      options={typeVizualizationOptions}
                      onChange={(selectedOption) => {
                        handleAmplitudeEvent('Filter Updated', { label: "Agrupamento", value: selectedOption.label })
                        setTypeVizualizationSelected(selectedOption)
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid
              item
              xl={size.grid4}
              lg={size.grid4}
              md={size.grid4}
              sm={size.grid4}
              xs={size.grid4}
              data-cy="divSelectDataCalendario"
            >
              <Paper elevation={0} className={classes.paper}>
                <Grid container>
                  <Grid item xs={1} style={{ alignSelf: "center" }}>
                    <Today />
                  </Grid>
                  <Grid item xs={11}>
                    <DatePicker
                      locale="pt-BR"
                      label
                      placeholderText="Escolha a data"
                      selected={startDate}
                      value={startDate}
                      onChange={(date) => handleSelectDate(date)}
                      dateFormat={typeVizualizationSelected.formatDate}
                      showMonthYearPicker={
                        typeVizualizationSelected.value !== 3
                      }
                      showYearPicker={typeVizualizationSelected.value === 3}
                      showFullMonthYearPicker
                      showFourColumnMonthYearPicker
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.wrapperContent}>
            <GridContextProvider onChange={onChange}>
              {loading ? (
                <EvolutiveSkeleton />
              ) : (
                <div className={classes.container}>
                  <GridDropZone
                    className={classes.dropzone}
                    id="charts"
                    boxesPerRow={3}
                    rowHeight={300}
                  >
                    {charts.length ? (
                      charts.map((item, index) => {
                        return (
                          <GridItem key={item.title}>
                            <Box
                              bgcolor="white"
                              borderRadius={12}
                              width="96%"
                              height={280}
                              padding={2}
                              display="flex"
                              flexDirection="column"
                              data-cy={boxDataCy(item?.title)}
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="h4"
                                  className={classes.chartTitle}
                                  data-cy={getDataCyTag(item?.title)}
                                >
                                  {item?.title}
                                </Typography>
                                <DragIndicator />
                              </Box>
                              <Box mt="22px" borderRadius={12}>
                                <ResponsiveContainer width="100%" height={200}>
                                  <AreaChart
                                    width={500}
                                    height={400}
                                    data={charts[index].evolutive}
                                    margin={{
                                      top: 10,
                                      right: 30,
                                      left: 0,
                                      bottom: 0,
                                    }}
                                  >
                                    <CartesianGrid
                                      strokeDasharray="1 3"
                                      vertical={false}
                                    />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <defs>
                                      <linearGradient
                                        id="splitColor"
                                        x1="1"
                                        y1="0"
                                        x2="1"
                                        y2="1"
                                      >
                                        <stop
                                          offset={"30%"}
                                          stopColor="green"
                                          stopOpacity={1}
                                        />
                                        <stop
                                          offset={"50%"}
                                          stopColor="yellow"
                                          stopOpacity={1}
                                        />
                                        <stop
                                          offset={"100%"}
                                          stopColor="red"
                                          stopOpacity={1}
                                        />
                                      </linearGradient>
                                    </defs>
                                    <Tooltip
                                      labelFormatter={(value) => {
                                        return [
                                          typeVizualizationSelected.label2,
                                          value,
                                        ];
                                      }}
                                      formatter={(value) => {
                                        return [value, item.title];
                                      }}
                                    />
                                    <Area
                                      type="monotone"
                                      dataKey="value"
                                      stroke="gray"
                                      fill="url(#splitColor)"
                                    />
                                  </AreaChart>
                                </ResponsiveContainer>
                              </Box>
                            </Box>
                          </GridItem>
                        );
                      })
                    ) : (
                      <Infobox />
                    )}
                  </GridDropZone>
                </div>
              )}
            </GridContextProvider>
          </Grid>
        </Grid>
      </PermissionsGate>
    </Aux>
  );
}
