import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'
import useStyles from './styles'
import Aux from 'hoc/auxiliar'
import Widget from 'components/Widget/Widget'
import { makeColumns } from './tableColumns'
import { useHistory, useLocation } from 'react-router-dom'

// contexts 
import { useSelectDrivers } from "context/useSelectDrivers"
import { setStepAdd, setStepDeduct } from 'redux/features/driversMonitoringSlice'
import { useAppDispatch } from 'redux/store'
import { useWindowSize } from "../../../hooks/useWindowsSize";
import TableResponsive from "../../../components/TableResponsive";
import { handleAmplitudeEvent } from 'services/amplitude'

const DriversMonitoringTripList = () => {
  const dispatch = useAppDispatch()
  const { state } = useLocation()
  const history = useHistory()

  const size = useWindowSize()
  const columns = makeColumns({ size })
  const classes = useStyles(size.mobile)
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [tripListDetailsData, setTripListDetailsData] = useState(null)
  const [id, setId] = useState();

  const handleClickDetail = () => {
    handleAmplitudeEvent('View Details Clicked', {
      driver_id: state.driverId,
      driver_name: state.name,
      vehicle_id: selectedRowData.vehicleId,
      vehicle_name: selectedRowData.vehicleIdentification,
      start_date: selectedRowData.startDate,
      end_date: selectedRowData.endDate,
      total_mileage: selectedRowData.achievedMileage,
      score: selectedRowData.score
    })
    dispatch(setStepAdd())
    history.push({
      pathname: "/gofurther/dashboard/general/driversMonitoring",
      state: {
        screen: 'driversMonitoring',
        startDate: selectedRowData.origin.date,
        finishDate: selectedRowData.destination.date,
        vehicleId: selectedRowData,
        detail: {
          ...state,
          data: [selectedRowData],
          endDate: selectedRowData.destination.date,
          driverId: state.driverId
        },
      },
    })
  }

  useEffect(() => {
    setTripListDetailsData(state.tripList)
  }, [state])

  useEffect(() => {
    handleAmplitudeEvent('Drivers Trip List Screen Viewed', { driver_id: state.driverId, driver_name: state.name, vehicle_id: state.tripList[0].vehicleId, vehicle_name: state.vehicle, total_trips: state.tripList.length })
  }, [])

  return (
    <Aux>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.marginTop}>
            <Widget
              disableWidgetMenu
              title="Relatório de viagens detalhadas">
              <Grid item container xl={12} xs={12} justifyContent={'space-between'} data-cy="divMotoristaVeiculo">
                <Grid item component={Paper} className={classes.infoDriverContainer} xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid12} xs={size.grid12} data-cy="divMotorista">
                  <Typography className={classes.label}>Motorista: </Typography>
                  <strong className={classes.driverName}>{state.name}</strong>
                </Grid>
                <Grid component={Paper} className={classes.infoDriverContainer} item xl={size.grid6} lg={size.grid6} md={size.grid6} sm={size.grid12} xs={size.grid12} data-cy="divVeiculo">
                  <Typography className={classes.label}>Veículo: </Typography>
                  <strong className={classes.driverName}>{state.vehicle}</strong>
                </Grid>
              </Grid>
              <Grid item xl={12} xs={12} className={classes.marginTop} data-cy="tableViagens">
                {tripListDetailsData && <TableResponsive
                  tableBodyMaxHeight={size.mobile ? size.height - 450 + 'px' : size.height - 350 + 'px'}
                  data={tripListDetailsData}
                  columns={columns}
                  options={{
                    rowsPerPage: 100,
                    setRowProps: (row, index) => {
                      return index === id ? { style: { backgroundColor: '#FFF5CB', cursor: 'pointer', width: '100%' } } : null
                    },
                    onRowClick: (data, data2) => {
                      const newSelectedRowData = tripListDetailsData.find(
                        (_info, index) => index === data2.dataIndex,
                      );
                      setId(data2.dataIndex);
                      setSelectedRowData(newSelectedRowData);
                      const amplitudeData = {
                        driver_id: state.driverId,
                        driver_name: state.name,
                        vehicle_id: newSelectedRowData.vehicleId,
                        vehicle_name: newSelectedRowData.vehicleIdentification,
                        start_date: newSelectedRowData.startDate,
                        end_date: newSelectedRowData.endDate,
                        total_mileage: newSelectedRowData.achievedMileage,
                        score: newSelectedRowData.score
                      }
                      handleAmplitudeEvent('Trip Selected', amplitudeData)
                    },
                  }}
                  pointer={true}
                  tableName="drivers-monitoring-tripList"
                />}
              </Grid>
            </Widget>
            {/* botoes */}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box marginTop={size.mobile ? "25px" : 0}>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.buttonBack}
                      data-cy="buttonVoltar"
                      onClick={() => {
                        handleAmplitudeEvent('Backward Button Clicked')
                        dispatch(setStepDeduct());
                        history.goBack();
                      }}
                    >
                      Voltar
                    </Button>
                  </Box>
                </Grid>
                <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                  <Box >
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleClickDetail}
                      disabled={!selectedRowData}
                      className={classes.buttonDetail}
                      data-cy="buttonVerDetalhes"
                    >
                      Ver detalhes
                    </Button>
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Aux>
  )
}

export default DriversMonitoringTripList
