import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState, useRef } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import Title from "componentsNew/atoms/title/title";
import FilterStatus from "componentsNew/molecules/filterStatus/filterStatus";
import Map from "componentsNew/molecules/map/map";
import MultiSelect from "components/MultiSelect";
import StatusEmpty from "componentsNew/atoms/statusEmpty/statusEmpty";
import { getVehicleStatus } from "services/map";
import { useWebSocketLocationGroupVehicleId } from "hooks/useWebSocketLocationGroupVehicleId";
import { useAppDispatch, useAppSelector } from "redux/store";
import { orderVehiclesByCrescent } from "helpers/vehicles";
import { getVehiclesByOperation } from "services/vehicle";
import { handleAmplitudeEvent } from "services/amplitude";
import { setLiveMapMenuSelected } from "redux/features/liveMapSlice";
import { useDebouncedCallback } from "use-debounce";
import { ModalReloadWebSocket } from "./modal";
import "./liveMap.css";

const zoom = 5
const locationCenter = {
  lat: -15.823438,
  lng: -47.727483,
}
const filterStatus = [true, true, true, true, true, true]

const LiveMap = () => {
  const dispatch = useAppDispatch();
  const { menuSelected } = useAppSelector((state) => state.liveMap);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [vehiclesSelect, setVehiclesSelect] = useState([]);
  const [modal, setModal] = useState(false);
  const modalDetails = useRef([]);
  const { currentCustomer } = useAppSelector((state) => state.global.user);
  const { points, closeConnection } = useWebSocketLocationGroupVehicleId(setLoading, setStatus);
  const memoryInfo = window.performance.memory;

  const filters = useMemo(() => {
    return [
      {
        icon: 1,
        iconColor: "blue",
        label: "Motor ligado parado",
        total: points.filter((item) => item.color === "blue").length ?? 0,
        checkbox: false,
        defaultChecked: true,
      },
      {
        icon: 1,
        iconColor: "green",
        label: "Em movimento",
        total: points.filter((item) => item.color === "green").length ?? 0,
        checkbox: false,
        defaultChecked: true,
      },
      {
        icon: 1,
        iconColor: "yellow",
        label: "Risco de pane seca",
        total: points.filter((item) => item.color === "yellow").length ?? 0,
        checkbox: false,
        defaultChecked: true,
      }/* ,
      {
        icon: 1,
        iconColor: "gray",
        label: "Parado",
        total: points.filter((item) => item.color === "gray").length ?? 0,
        checkbox: false,
        defaultChecked: true,
      },
      {
        icon: 1,
        iconColor: "white",
        label: "Sem conexão",
        total: points.filter((item) => item.color === "white").length ?? 0,
        checkbox: false,
        defaultChecked: true,
      },
      {
        icon: 1,
        iconColor: "red",
        label: "Não encontrado",
        total: points.filter((item) => item.color === "red").length ?? 0,
        checkbox: false,
        defaultChecked: true,
      }, */
    ]
  }, [points]);

  //buscando os veiculos para popular o select
  const fetchVehicles = useCallback(async () => {
    try {
      const response = await getVehiclesByOperation(currentCustomer);
      if (response.data.customers) {
        let orderedVehiclesByCustomer = response.data.customers.sort(
          (a, b) => (a.name > b.name) - (a.name < b.name)
        );

        orderedVehiclesByCustomer.map((option, i) => {
          const optionVehicles = option.vehicles
            .filter(item => item?.devicesHistory !== null)
            .filter(item => item?.currentDevice?.identification !== '');

          orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
        });
        
        setVehiclesSelect(orderedVehiclesByCustomer);
      }
    } catch (err) {
      toast.error(
        "Erro ao carregar lista de Veículos. Entre em contato com o suporte.",
      );
    }
  }, [currentCustomer]);

  const debounced = useDebouncedCallback((value) => {
    if (value) {
      dispatch(setLiveMapMenuSelected({
        vehicles: value
      }));
    }
  }, 2000);

  //selecionando os veiculos para exibir no mapa
  const handleSelectedData = (selected) => {
    dispatch(setLiveMapMenuSelected({
      vehicles: selected
    }));
    handleAmplitudeEvent('Filter Updated', { label: "Frota", value: selected.map(item => item?.identification + ' - ' + item?.plate).join(", ") })
  };

  const handleModalDetails = async (vehicleID) => {
    try {
      const response = await getVehicleStatus(vehicleID);
      modalDetails.current[vehicleID] = response;
    } catch (error) {
      modalDetails.current[vehicleID] = null;
      console.log('error', error);
    }
  }

  //carregamento inicial
  useLayoutEffect(() => {
    handleAmplitudeEvent('Live Map Screen Viewed');
    fetchVehicles();
  }, []);

  // controle de uso de memoria
  useEffect(() => {
    const percentageMemory = ((100 / memoryInfo.jsHeapSizeLimit) * memoryInfo.usedJSHeapSize).toFixed(2)
    if (percentageMemory > 90) {
      closeConnection()
      setModal(true)
    }
  }, [memoryInfo])

  //alterando filtros
  useEffect(() => {
    setLoading(true);
    if (filterStatus.length > 0) {
      const vehiclesNew = [];
      points.map((op) => {
        filters.map((st, i) => {
          if (op.color === st.iconColor && filterStatus[i]) {
            vehiclesNew.push(op);
          }
        });
      });
      setLoading(false);
    }
  }, [filterStatus]);

  useEffect(() => {
    if (menuSelected && menuSelected.vehicles?.length > 0 && points.length === 0) {
      debounced(menuSelected.vehicles);
    }
    (!!menuSelected && menuSelected.vehicles?.length > 0) && !points.length && handleAmplitudeEvent('Live Map Loaded', { total_vehicle: menuSelected.vehicles.length })
  }, [])

  try {
    return (
      <div className={`LiveMap-container`}>
        <ModalReloadWebSocket modal={modal} setModal={setModal} />
        <Grid container className={`LiveMap-container-top`}>
          <Grid item className={`LiveMap-title`}>
            <Title value={"Mapa ao vivo"} />
            <span className={`LiveMap-description1`}>
              Visualize a sua frota em tempo real e tenha mais <br />
              controle sobre os status de cada veículo
            </span>
          </Grid>
          <Grid item className={`LiveMap-select`}>
            <MultiSelect
              listData={vehiclesSelect}
              handleSelectedListData={handleSelectedData}
              selectedData={menuSelected?.vehicles}
            />
          </Grid>
        </Grid>
        {menuSelected?.vehicles?.length > 0 ? (
          status === "error" ? (
            <StatusEmpty
              title={"Ocorreu um erro ao realizar a busca."}
              description="Nenhum resultado para exibir, entre em contato com o suporte ou tente novamente mais tarde."
            />
          ) : status === "empty" ? (
            <StatusEmpty
              title={"Não há resultados para mostrar."}
              description="Nenhum resultado para exibir com esses filtros, verifique-o ou entre em contato com o suporte caso tenha certeza que era para exibir informações."
            />
          ) : (
            <>
              {filters?.length > 0 && (
                <>
                  <span className={`LiveMap-description1`}>
                    Ative ou desative selecionando abaixo o tipo de status que
                    deseja visualizar
                  </span>
                  <FilterStatus props={filters} />
                </>
              )}
              <Map
                points={points}
                initLocationCenter={locationCenter}
                initZoom={zoom}
                loading={loading}
                height={"75vh"}
                closeConnection={closeConnection}
                modalDetails={modalDetails}
                handleModalDetails={handleModalDetails}
              />
            </>
          )
        ) : (
          <StatusEmpty
            title="Selecione o filtro acima."
            description="Para realizar a busca, é necessário selecionar uma ou mais frota."
          />
        )}
      </div>
    );
  } catch (error) {
    return (
      <StatusEmpty
        title={"Ocorreu um erro ao realizar a busca."}
        description="Nenhum resultado para exibir, entre em contato com o suporte ou tente novamente mais tarde."
      />
    )
  }

};

export default LiveMap;

LiveMap.defaultProps = {};
