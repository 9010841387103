import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { useDebouncedCallback } from 'use-debounce';
import { format } from "date-fns";
import { toast } from "react-toastify";
import Select from "react-select"
import makeAnimated from "react-select/animated";

import Aux from "hoc/auxiliar";

import Calendar from "components/Calendar";
import Infobox from "components/Infobox";
import ListVehiclesCompareSkeleton from "components/Skeletons/ListVehiclesCompareSkeleton";
import LottieComponent from "components/LottieComponent";
import ModalComparisonTripListDetail from "components/ModalComparisonTripListDetail";
import MultiSelect from "components/MultiSelect";
import PermissionsGate from "components/PermissionsGate";
import TableResponsive from "components/TableResponsive";
import Widget from "components/Widget";
import { ValidationMessageComponent } from "components/ValidationMessageComponent";

// redux
import { useAppDispatch, useAppSelector, store } from 'redux/store';
import { clearComparisonPagination, setPaginationReducer } from 'redux/features/comparisonSlice';
import { useSelect } from "context/useSelect";
import { useWindowSize } from "hooks/useWindowsSize";

// services
import { getCustomersById } from "services/customers";
import { getDriversPerformance } from "services/driver";
import {
  getVehiclesByOperation,
  getDriversHistory,
  getVehicleValidation
} from "services/vehicle";
import {
  getVehiclesPerformancePackLimitPage,
} from "services/fleetPerformance";
import { handleAmplitudeEvent } from "services/amplitude";

// helpers
import { orderVehiclesByCrescent } from 'helpers/vehicles';
import { makeColumns } from './tableColumns';
import { generateRange } from 'helpers/operations';
import { getCurrentMonth } from 'helpers/functions';

// styles
import useStyles from "./styles";
import { colourStyles } from "./colourStyles";
import lottieEmptyData from '../../../assets/lottieFiles/lottieEmptyData.json'
import lottieLoading from '../../../assets/lottieFiles/lottieLoading.json'

export const selectFilterOptions = [{
  id: 0,
  label: "Data de corte",
  name: "Data de corte",
  value: 0
}, {
  id: 1,
  label: "Calendário",
  name: "Calendário",
  value: 1
}]

export default function Comparison() {
  const { currentCustomer, email } = useAppSelector((state) => state.global.user);
  const { pagination } = useAppSelector((state) => state.comparison);
  const dispatch = useAppDispatch()

  const size = useWindowSize()

  const classes = useStyles();

  const animatedComponents = makeAnimated();

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const firstOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const dateFormatTemplate = "yyyy-MM-dd'T'HH:mm:ssXX";

  const [loading, setLoading] = useState({
    image: false,
    skeleton: true,
  });
  const [customersDates, setCustomersDates] = useState(null);
  const [selectedComparisonTripListDetail, setSelectedComparisonTripListDetail] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDataRow, setSelectedDataRow] = useState(null);
  const [selectedDates, setSelectedDates] = useState({
    initialDate: format(firstOfMonth, dateFormatTemplate),
    finalDate: format(new Date(), "yyyy-MM-dd'T'23:59:59XX"),
  });
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [vehiclesPerformance, setVehiclesPerformance] = useState(null);
  const [download, setDownload] = useState({
    link: "",
    fileName: "",
    params: {
      vehicles: [],
      startDate: null,
      endDate: null,
      email: null
    }
  });
  const [validationMessageComponent, setValidationMessageComponent] = useState(false)
  const [validationMessage, setValidationMessage] = useState(false)
  const [isCalendarFilter, setIsCalendarFilter] = useState(selectFilterOptions[0]);

  const {
    selectedCutOffDate,
    setSelectedCutOffDate,
    cutOffDatesOptions,
    setCutOffDatesOptions,
    onChangeCutOffDate,
  } = useSelect()

  const [selectedYear, setSelectedYear] = useState({
    value: today.getFullYear(),
    label: today.getFullYear(),
  });

  const selectedOperations = useRef([]);

  const isEmptyDatatable = (!loading.skeleton && !loading.image && vehiclesPerformance && !vehiclesPerformance.length)
  const columns = makeColumns(size);

  const yearOptions = [];
  const currentYear = today.getFullYear();
  const lastYearToShow = today.getMonth() > 10 ? currentYear + 1 : currentYear;
  for (let i = 2020; i <= lastYearToShow; i++) {
    yearOptions.push({ value: i, label: i });
  }

  const fetchVehiclesPerformance = async (startDate, endDate) => {
    setLoading({ ...loading, skeleton: true });
    const id = setTimeout(async () => {
      setLoading({ ...loading, skeleton: false, image: true });
    }, 3000);
    dispatch(setPaginationReducer({ isLoading: true }));
    try {
      const vehicleList = selectedVehicles.map((vehicle) => vehicle.id);
      let response = null;
      if (!isCalendarFilter) {
        return;
      }
      const updatedPagination = store.getState().comparison.pagination;
      response = await getVehiclesPerformancePackLimitPage(
        vehicleList,
        startDate,
        endDate,
        updatedPagination.rowsPerPage,
        updatedPagination.page,
      );
      const { performances } = response.data?.data
      if (!performances) {
        setVehiclesPerformance([]);
        return;
      }
      const formatedResponse = performances.map(
        (performance, index) => {
          return {
            ...performance
          };
        },
      );

      dispatch(setPaginationReducer({ isLoading: false, count: response.data?.data.totalItems || 0 }))
      setVehiclesPerformance(formatedResponse);
      setDownload({
        link: `/web/v2/performance/xls/byvehicle`,
        fileName: `comparativo_${format(
          new Date(selectedDates.initialDate),
          "dd-MM-yyyy-HH-mm",
        )}_${format(new Date(selectedDates.finalDate), "dd-MM-yyyy-HH-mm")}.xlsx`,
        params: {
          vehicles: vehicleList,
          startDate: selectedDates.initialDate,
          endDate: selectedDates.finalDate,
          email,
        }
      });
    } catch (err) {
      toast.warning(err.message || "Erro ao carregar lista de Veículos. Entre em contato com o suporte.");
    } finally {
      setLoading({ ...loading, image: false, skeleton: false });
      clearTimeout(id);
    }
  };

  const handleModalOpen = async (data, row) => {
    const selectedRowVehicle = vehiclesPerformance[row.dataIndex]
    handleAmplitudeEvent('Vehicle Selected', { vehicle_id: selectedRowVehicle.id, vehicle_name: selectedRowVehicle.identification, score: selectedRowVehicle.score }, "ROW DATA INDEX 194")
    const vehicleId = selectedRowVehicle.id
    try {
      const responseDriversHistory = await getDriversHistory(
        vehicleId,
        selectedDates.initialDate,
        selectedDates.finalDate,
      );
      if (!responseDriversHistory.data.drivers) {
        toast.warning("Não há nenhum motorista vinculado no período selecionado.");
        return;
      } else {
        const uniqueDrivers = [
          ...responseDriversHistory.data.drivers
            .reduce(
              (map, obj) => map.set(obj.driverId, obj.driverId),
              new Map(),
            )
            .values(),
        ];

        const responseDriversPerformance = await getDriversPerformance(
          uniqueDrivers,
          vehicleId,
          selectedDates.initialDate,
          selectedDates.finalDate,
        );
        const { performances } = responseDriversPerformance.data.data
        if (!performances) {
          toast.error("Veículo sem viagens registradas.");
          return;
        }
        const trips = performances.map((driver) =>
          driver.tripsScores.map((trip, index) => ({
            ...trip,
            driverName: driver.driverName,
            id: index,
          })),
        );

        setSelectedComparisonTripListDetail({
          drivers: uniqueDrivers,
          vehicleId: vehicleId,
          startDate: selectedDates.initialDate,
          endDate: selectedDates.finalDate,
          email: email,
          limit: 10000
        });

        setSelectedDataRow(trips.flat());
        setModalOpen(true);
        handleAmplitudeEvent('Drivers List Modal Viewed', { vehicle_id: vehicleId, vehicle_name: selectedRowVehicle, total_trips: trips.flat().length })

      }
    } catch (err) {
      console.log(err)
      toast.error("Erro ao carregar lista. Entre em contato com o suporte.");
    }
  };

  const handleModalClose = () => {
    handleAmplitudeEvent('Backward Button Clicked');
    setModalOpen(false);
  };

  const checkVehicles = async (initialDate, finalDate) => {
    let vehicles = selectedVehicles.map((vehicle) => vehicle.id);
    const validation = await getVehicleValidation(
      vehicles,
      initialDate,
      finalDate
    )
    const { response: vehicleValidationConfig } = validation.data
    setValidationMessage(vehicleValidationConfig)
    setValidationMessageComponent(vehicleValidationConfig !== 'ok')
    return vehicleValidationConfig !== 'ok';
  }

  const handleSelectDate = async (initialDate, finalDate) => {
    setValidationMessageComponent(false)
    setSelectedDates({
      initialDate: initialDate,
      finalDate: finalDate,
    });
  };

  const setOperationVehicles = () => {
    if (selectedOperations.current) {
      const allVehiclesOperationArray = [];
      vehicles.map(
        (operation) =>
          selectedOperations.current.includes(operation.id) &&
          operation.vehicles.forEach((vehicle) => {
            allVehiclesOperationArray.push({
              identification: vehicle.currentDevice.identification,
              id: vehicle.id,
            });
          }),
      );
      setSelectedVehicles(allVehiclesOperationArray);
    }
  };

  const handleSelectedVehicles = async (selected) => {
    handleAmplitudeEvent('Filter Updated', { label: "Frota", value: selected.map(item => item?.identification + ' - ' + item?.plate).join(", ") })
    setValidationMessageComponent(false)
    setSelectedVehicles(selected);
    if (selected.length > 0) {
      try {
        let vehicles = []
        vehicles = selected.map((vehicle) => vehicle.id);
        const validation = await getVehicleValidation(vehicles,
          selectedDates.initialDate,
          selectedDates.finalDate)
        const { response: messageResponse } = validation.data
        setValidationMessage(messageResponse)
        if (messageResponse !== 'ok') {
          setValidationMessageComponent(true)
        }
      } catch (error) {
        toast.error('Erro ao validar configurações do veículo. Por favor, entre em contato com o suporte.')
      }
    } else {
      setSelectedVehicles([])
      setIsCalendarFilter(selectFilterOptions[0])
      return false
    }
  };

  const handleCutOffDates = (customersOptions) => {
    const startingDay = customersOptions.starting_day;
    const finishingDay = customersOptions.finishing_day;
    const period = customersOptions.period;
    if (startingDay && finishingDay && !isNaN(period)) {
      const cutOffDate = generateRange(startingDay, finishingDay, period, selectedYear.value);
      const selectedMonth = getCurrentMonth(finishingDay);
      setCutOffDatesOptions(cutOffDate);
      setSelectedCutOffDate(cutOffDate[selectedMonth]);
      const initialDate = format(cutOffDate[selectedMonth].startDate, dateFormatTemplate)
      const finalDate = format(cutOffDate[selectedMonth].finishDate, "yyyy-MM-dd'T'23:59:59XX")
      handleSelectDate(initialDate, finalDate)
    } else {
      setCutOffDatesOptions(null);
      setSelectedCutOffDate(null);
    }
  };

  const fetchData = async () => {
    setLoading({ ...loading, skeleton: true });
    const id = setTimeout(async () => {
      setLoading({ ...loading, skeleton: false, image: true });
    }, 3000);

    try {
      const responseCustomers = await getCustomersById(currentCustomer);
      if (responseCustomers.status !== 200) {
        throw new Error("Error fetching customers");
      }
      const customersOptions = responseCustomers.data.customers[0];

      setCustomersDates(customersOptions);
      handleCutOffDates(customersOptions);
      const response = await getVehiclesByOperation(currentCustomer);
      if (response.data.customers) {
        let orderedVehiclesByCustomer = response.data.customers.sort(
          (a, b) => (a.name > b.name) - (a.name < b.name)
        );

        orderedVehiclesByCustomer.map((option, i) => {
          const optionVehicles = option.vehicles
            .filter(item => item?.devicesHistory !== null)
            .filter(item => item?.currentDevice?.identification !== '');

          orderedVehiclesByCustomer[i].vehicles = orderVehiclesByCrescent(optionVehicles);
        });

        setVehicles(orderedVehiclesByCustomer);
      }
    } catch (error) {
      toast.error("Erro ao buscar dados. Contate o suporte.");
      setCutOffDatesOptions(null);
      setSelectedCutOffDate(null);
      console.log(error);
    } finally {
      clearTimeout(id);
      setLoading({ ...loading, skeleton: false, image: false });

    }
  }

  const handleTablePagination = (action, tableState) => {
    switch (action) {
      case 'changePage':
        dispatch(setPaginationReducer({ isLoading: true, page: tableState.page + 1 }))
        fetchVehiclesPerformance(selectedDates.initialDate, selectedDates.finalDate)
        break;
      case 'changeRowsPerPage':
        dispatch(setPaginationReducer({ isLoading: true, rowsPerPage: tableState.rowsPerPage }))
        fetchVehiclesPerformance(selectedDates.initialDate, selectedDates.finalDate)
        break;
      default:
        break;
    }
  }

  const handleVehicleListRender = useCallback(() => {
    if (selectedVehicles.length === 0 || (!isCalendarFilter && !selectedDates.startDate)) {
      return <Infobox />
    }
    if (loading.skeleton) {
      return <ListVehiclesCompareSkeleton />
    }
    if (loading.image) {
      return (
        <Grid container alignItems="center" justifyContent="center" direction="column" className={classes.wrapperLoadingImage}>
          <Typography variant="h3" className={classes.loadingText}>Aguarde que estamos carregando seus dados...</Typography>
          <LottieComponent animationData={lottieLoading} width={!size.mobile ? 870 : 400} height={'auto'} />
        </Grid>
      )
    }

    if (isEmptyDatatable) {
      return (
        <Grid container alignItems="center" justifyContent="center" direction="column" className={classes.wrapperLoadingImage}>
          <Typography variant="h3" className={classes.loadingText}>Opa, não encontramos dados no período selecionado, selecione outro período.</Typography>
          <LottieComponent animationData={lottieEmptyData} width={!size.mobile ? 870 : 400} height={'auto'} />
        </Grid>
      );
    }
    vehiclesPerformance && vehiclesPerformance.length > 0 && handleAmplitudeEvent('Vehicle Comparisson Loaded', { total_vehicles: selectedVehicles.length, periodo_type: isCalendarFilter.label, year: selectedYear.label, period: selectedCutOffDate.label })
    return (
      <Grid>
        {validationMessageComponent && <ValidationMessageComponent message={validationMessage} isComparison />}
        <Grid container data-cy="tableContent">
          <Grid item xs={12} className={classes.contentDataTable}>
            <Widget disableWidgetMenu title="Comparativo">
              <Grid item xs={12} className={classes.table} data-cy="tableRole">
                {vehiclesPerformance && vehiclesPerformance.length > 0 &&
                  <TableResponsive
                    columns={columns}
                    data={vehiclesPerformance}
                    options={{
                      onRowClick: handleModalOpen,
                      rowsPerPage: pagination.rowsPerPage,
                      serverSide: true,
                      count: pagination.count,
                      onTableChange: handleTablePagination,
                      isLoading: pagination.isLoading,
                      page: pagination.page - 1,
                      search: false,
                      filter: false
                    }}
                    pointer={true}
                    download={download}
                    email={true}
                    tableName="comparison"
                  />
                }
              </Grid>
            </Widget>

          </Grid>
        </Grid>
      </Grid>
    );
  }, [loading, selectedVehicles, vehiclesPerformance]);

  const debounced = useDebouncedCallback(() => {
    const checkedVehicles = checkVehicles(selectedDates.initialDate, selectedDates.finalDate)
    if (checkedVehicles) {
      fetchVehiclesPerformance(selectedDates.initialDate, selectedDates.finalDate);
    } else {
      setLoading({ ...loading, skeleton: false });
    }
  }, 1000);

  useEffect(() => {
    if (currentCustomer > 0) {
      fetchData();
      setOperationVehicles();
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (selectedVehicles.length > 0 && selectedCutOffDate) {
      handleSelectDate(format(selectedCutOffDate.startDate, dateFormatTemplate), format(selectedCutOffDate.finishDate, "yyyy-MM-dd'T'23:59:59XX"))
    }
  }, [selectedVehicles, selectedCutOffDate])

  useEffect(() => {
    if (selectedVehicles.length > 0 && selectedDates) {
      setVehiclesPerformance([]);
      setLoading({ ...loading, skeleton: true });
      debounced();
    }
  }, [selectedVehicles, selectedDates])

  useEffect(() => {
    if (selectedYear) {
      customersDates && handleCutOffDates(customersDates);
    }
  }, [selectedYear]);

  useEffect(() => {
    dispatch(clearComparisonPagination())
  }, [dispatch]);

  useEffect(() => {
    handleAmplitudeEvent('Vehicles Comparisson Screen Viewed');
  }, [])

  const styleFormat = isCalendarFilter?.label === "Calendário";

  return (
    <Aux>
      <PermissionsGate scopes={['can_view_comparison']}>
        <ModalComparisonTripListDetail
          open={modalOpen}
          handleClose={handleModalClose}
          data={selectedDataRow}
          selectedComparisonTripListDetail={selectedComparisonTripListDetail}
        />
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.spacingContainer}>
          <Grid item xl={styleFormat ? size.grid4 : size.grid3}
            lg={styleFormat ? size.grid4 : size.grid3}
            md={styleFormat ? size.grid4 : size.grid3}
            sm={styleFormat ? size.grid4 : size.grid3}
            xs={styleFormat ? size.grid4 : size.grid3}
            style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}>
            <MultiSelect
              isDetail={false}
              isSingleMode={false}
              listData={vehicles}
              handleSelectedListData={handleSelectedVehicles}
              selectedData={selectedVehicles}
            />
          </Grid>
          <Grid item xl={styleFormat ? size.grid4 : size.grid3}
            lg={styleFormat ? size.grid4 : size.grid3}
            md={styleFormat ? size.grid4 : size.grid3}
            sm={styleFormat ? size.grid4 : size.grid3}
            xs={styleFormat ? size.grid4 : size.grid3}
            style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
            data-cy="divSelectDataCorte"
          >
            <Paper elevation={0} className={classes.paper}>
              <Select
                placeholder="Tipo de Filtro de data"
                options={selectFilterOptions}
                styles={colourStyles}
                onChange={(item) => setIsCalendarFilter(item)}
                value={isCalendarFilter}
                defaultValue={selectFilterOptions[0]}
                components={animatedComponents}
              />
            </Paper>
          </Grid>
          {isCalendarFilter?.label !== "Calendário" && (
            <Grid item xl={styleFormat ? size.grid4 : size.grid3}
              lg={styleFormat ? size.grid4 : size.grid3}
              md={styleFormat ? size.grid4 : size.grid3}
              sm={styleFormat ? size.grid4 : size.grid3}
              xs={styleFormat ? size.grid4 : size.grid3}
              style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}
              data-cy="divSelectDataAno"
            >
              <Paper elevation={0} className={classes.paper}>
                <Select
                  placeholder="Ano"
                  options={yearOptions.reverse()}
                  styles={colourStyles}
                  onChange={(item) => setSelectedYear(item)}
                  value={selectedYear}
                  defaultValue={selectedYear}
                  components={animatedComponents}
                />
              </Paper>
            </Grid>
          )}
          {isCalendarFilter?.label === "Calendário" ? (
            <Grid item xl={styleFormat ? size.grid4 : size.grid3}
              lg={styleFormat ? size.grid4 : size.grid3}
              md={styleFormat ? size.grid4 : size.grid3}
              sm={styleFormat ? size.grid4 : size.grid3}
              xs={styleFormat ? size.grid4 : size.grid3}
              style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}>
              <Paper elevation={0} className={classes.paperCalendar}>
                <Calendar
                  selectedDates={{
                    initialDate: selectedDates?.initialDate,
                    finalDate: selectedDates?.finalDate
                  }}
                  handleCalendar={(startDate, endDate) => {
                    handleSelectDate(format(startDate, dateFormatTemplate), format(endDate, dateFormatTemplate))
                  }}
                />
              </Paper>
            </Grid>) : (
            <>
              {cutOffDatesOptions === null ? (
                <Grid item xl={styleFormat ? size.grid4 : size.grid3}
                  lg={styleFormat ? size.grid4 : size.grid3}
                  md={styleFormat ? size.grid4 : size.grid3}
                  sm={styleFormat ? size.grid4 : size.grid3}
                  xs={styleFormat ? size.grid4 : size.grid3}
                  style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}>
                  <Paper elevation={0} className={classes.paper}>
                    <Typography>Não foram encontradas data de corte.</Typography>
                  </Paper>
                </Grid>
              ) : (
                <Grid item xl={styleFormat ? size.grid4 : size.grid3}
                  lg={styleFormat ? size.grid4 : size.grid3}
                  md={styleFormat ? size.grid4 : size.grid3}
                  sm={styleFormat ? size.grid4 : size.grid3}
                  xs={styleFormat ? size.grid4 : size.grid3}
                  style={size.mobile ? {} : { paddingLeft: 5, paddingRight: 5 }}>
                  <Paper elevation={0} className={classes.paper} data-cy="divDataCalendario">
                    <Select
                      placeholder="Data de corte"
                      options={cutOffDatesOptions}
                      styles={colourStyles}
                      components={animatedComponents}
                      onChange={(date) => onChangeCutOffDate(date)}
                      value={selectedCutOffDate}
                    />
                  </Paper>
                </Grid>)}
            </>
          )}
        </Grid>
        {handleVehicleListRender()}
      </PermissionsGate>
    </Aux>
  );
}
