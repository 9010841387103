import React from "react";
import { Typography } from "@material-ui/core";
import makeStyles from "./styles";
import classnames from "classnames";
import { timeConvert, valueToPositiveFloat } from "helpers/functions";
import { hasPermission } from "components/PermissionsGate";

export const makeColumns = (size) => {
  const classes = makeStyles();

  const getCell = (data, textBold, dataCy) => {
    return (
      <Typography
        variant="body2"
        className={classnames({
          [classes.textBold]: textBold,
        })}
        noWrap
        data-cy={dataCy}
      >
        {data}
      </Typography>
    );
  };

  const getCellIndicator = (value, dataCy) => {
    const data = `${value?.duration
      ? `${valueToPositiveFloat(value?.percentage, 2)}% (${timeConvert(value.duration)})`
      : "0% (00:00:00)"
      }`;
    return getCell(data, false, dataCy);
  };



  const columns = [
    {
      name: "customerName",
      label: size.mobile ? " " : "Empresa",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thEmpresa"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "identification",
      label: "Frota",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thFrota"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "plate",
      label: "Placa",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thPlaca"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "brand",
      label: "Marca",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thMarca"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "truckModel",
      label: "Modelo do veículo",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thModeloVeiculo"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "fabricationYear",
      label: "Ano de fabricação",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thAnoFabricacao"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "motor",
      label: "Motorização",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thMotorizacao"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "wheelDriver",
      label: "Tração",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thTracao"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "operationName",
      label: "Grupo de veículos",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thGrupoVeiculos"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thNotaGeral"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalMileage",
      label: "Km total",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thKmTotal"
          >
            {`${valueToPositiveFloat(value, 2)} Km`}
          </Typography>
        ),
        filter: false,
        sort: false,
      },
    },
    {
      name: "averageSpeed",
      label: "Velocidade média",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thVelocidadeMedia"
          >
            {`${valueToPositiveFloat(value, 2)} Km`}
          </Typography>
        ),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalConsumption",
      label: "Consumo total",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thConsumoTotal"
          >
            {`${valueToPositiveFloat(value, 2)} Km`}
          </Typography>
        ),
        filter: false,
        sort: false,
      },
    },
    {
      name: "consumptionAverage",
      label: "Média computador de bordo",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thMediaComputadorBordo"
          >
            {`${valueToPositiveFloat(value, 2)} Km`}
          </Typography>
        ),
        filter: false,
        sort: false,
      },
    },
    {
      name: "odometer",
      label: "Odômetro",
      options: {
        customBodyRender: (value) => (
          <Typography
            variant="body2"
            noWrap
            data-cy="thOdometro"
          >
            {`${valueToPositiveFloat(value, 2)} Km`}
          </Typography>
        ),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalBreakingOnHighSpeed",
      label: "Freadas em alta veloc.",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thFreadasAltaVelocidade"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "totalBreaking",
      label: "Freadas totais",
      options: {
        customBodyRender: (value) =>
          getCell(value, true, "thFreadasTotais"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.extraEconomicRange",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thInicioFaixaVerde"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.powerRange",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thAcelerandoAcimaVerde"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.leverage",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thAproveitamentoEmbalo"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.idle",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thMotorLigadoParado"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.speeding",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thExcessoVelocidade"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.cruiseControl",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thPilotoAutomatico"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.greenRange",
      label: "Faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thFaixaVerde"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.economicRange",
      label: "Final da faixa verde",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thFaixaEconomica"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.engineBrake",
      label: "Freio motor",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thFreioMotor"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.ecoRoll",
      label: "Eco-Roll/I-Roll",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thEcoRoll"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.movement",
      label: "Tempo em movimento",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thTempoMovimento"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.lowPressure",
      label: "Uso do pedal ideal",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thUsoPedalIdeal"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.medPressure",
      label: "Uso do pedal atenção",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thUsoPedalAtencao"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "indicators.higPressure",
      label: "Uso do pedal crítico",
      options: {
        customBodyRender: (value) => getCellIndicator(value, "thUsoPedalCritico"),
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];

  const filteredColumns = columns.filter((column) => {
    if (column.name === "indicators.ecoRoll") {
      return hasPermission({ scopes: ["can_view_eco_roll_indicator"] });
    }
    return true;
  });

  return filteredColumns;
};
