import api from "./api";
import { convertStringDate } from "helpers/functions";

export const getTelemetryData = async (vehicleId, startDate, endDate) => {
  try {
    // "acceleration_pedal_position": 0,
    // "vehicleId": "1",
    // "height": 488,
    // "latitude": -23.62476,
    // "longitude": -50.1480966,
    // "rpm": 0,
    // "time": "2021-07-22 23:54:01",
    // "total_fuel_consumption": 0,
    // "total_fuel_consumption_high_resolution": 0,
    // "total_mileage": 0
    //id,acceleration,acceleration_pedal_position,adblue,axle_index,axle_load_1,axle_load_2,axle_load_3,axle_load_4,axle_load_5,axle_pressure,batery_life,can_fuel_level,can_speed,clutch_pedal_position,clutch_pedal_status,coolant_temperature,cruise_control_status,daily_fuel_consumption,daily_mileage,device_open,deviceid,door_status,engine_oil_pressure,engine_oil_temperature,engine_run_time_until_the_next_maintenance,event_of_instantaneous_fuel_economy,fuel_temperature,gear,gps_course,gps_speed,gps_status,gpstime,gsm_status,height,ignition_key,imei,input_0,input_1,input_2,input_3,input_4,isvalid,latitude,lls1_status,lls1_temp,lls1_value,lls2_status,lls2_temp,lls2_value,lls3_status,lls3_temp,lls3_value,lls4_status,lls4_temp,lls4_value,lls5_status,lls5_temp,lls5_value,lls6_status,lls6_temp,lls6_value,longitude,mileage,mileage_until_the_next_maintenance,mileage_virt,output_status,parking_brake_status,protocol,rpm,satellite_quatity,seatbelt_status,service_brake_pedal_status,service_brake_pedal_status,time,total_fuel_consumption,total_fuel_consumption_high_resolution,total_mileage,total_mileage_filled,total_time_of_engine_operation,voltage
    const convertedDates = convertStringDate(startDate, endDate);
    const response = await api.get(`/telemetry-data?vehicleId=${vehicleId}&startDate=${convertedDates.startDate}&endDate=${convertedDates.endDate}&fields=acceleration,acceleration_pedal_position,adblue,can_fuel_level,can_speed,cruise_control_status,deviceid,gear,gps_course,gps_speed,gps_status,gpstime,gsm_status,height,ignition_key,latitude,lls1_status,lls1_temp,lls1_value,lls2_status,lls2_temp,lls2_value,lls3_status,lls3_temp,lls3_value,lls4_status,lls4_temp,lls4_value,lls5_status,lls5_temp,lls5_value,lls6_status,lls6_temp,lls6_value,longitude,mileage,mileage_virt,rpm,satellite_quatity,service_brake_pedal_status,service_brake_pedal_status,time,total_fuel_consumption,total_fuel_consumption_high_resolution,total_mileage,total_mileage_filled,total_time_of_engine_operation,voltage,imei,lastBondPoint&fix-invalid-points=false&start=0&length=999999999999999`);
    return response;
  } catch (err) {
    throw err;
  }
};
