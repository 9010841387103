import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { format } from "date-fns";
import { convertDateBr, convertHourMinBr }  from "helpers/dates";
import Rating from "@material-ui/lab/Rating";
import { makeValueStars, valueToStringFloat } from "helpers/functions";
import { Tooltip } from "@material-ui/core";
import makeStyles from "./styles";

export const makeColumns = ({ size }) => {
  const classes = makeStyles();
  const arranjo = [
    {
      name: "origin",
      label: "Origem",
      options: {
        customBodyRender: (value) => {
          if (value.date) {
            return (
              <Typography
                variant="body2"
                className={classes.textBold}
                noWrap={true}
                data-cy="thOrigem"
              >
                {size.mobile ?
                  convertHourMinBr(value.date) + ' - ' + value.name
                  : convertDateBr(value.date) + ' - ' + value.name
                }
              </Typography>
            );
          }
        },
      },
    },
    {
      name: "destination",
      label: "Destino",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classes.textBold}
              noWrap={true}
              data-cy="thDestino"
            >
              {size.mobile ?
                convertHourMinBr(value.date) + ' - ' + value.name
                : convertDateBr(value.date) + ' - ' + value.name
              }
            </Typography>
          );
        },
      },
    },
    /*  {
       name: "consumptionAvg",
       label: "Média"
     }, */
    {
      name: "achievedMileage",
      label: "Dist. percorrida (km)",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thDistanciaPercorridaKM">{valueToStringFloat(value)}</Typography>;
        },
      },
    },
    /* {
      name: "consumption",
      label: "Consumo (L)"
    }, */
    {
      name: "score",
      label: "Nota geral",
      options: {
        customBodyRender: (value) => {
          return <Typography variant="body2" data-cy="thNotaGeral">{value}</Typography>;
        },
      },
    },
    {
      name: "idleScore",
      label: "Motor ligado parado",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thMotorLigadoParado">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "extraEconomicDrivingScore",
      label: "Início da faixa verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thInicioFaixaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "leveragingScore",
      label: "Aproveitamento de embalo",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAproveitamentoEmbalo">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "powerScore",
      label: "Acelerando acima do verde",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thAcelerandoAcimaVerde">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "cruiseControlScore",
      label: "Piloto automático",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thPilotoAutomatico">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "defensiveDrivingScore",
      label: "Excesso de velocidade",
      options: {
        customBodyRender: (value) => {
          return (
            <Tooltip title={`Nota: ${value}`} data-cy="thExcessoVelocidade">
              <Box>
                <Rating
                  name="half-rating-read"
                  value={makeValueStars(value)}
                  precision={0.1}
                  readOnly
                  size="small"
                />
              </Box>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        searchable: false,
        filter: false,
        viewColumns: false,
        hideColumn: true,
      },
    },
  ];
  if (size.mobile) {
    arranjo.unshift({
      name: "header",
      label: " ",
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              variant="body2"
              className={classes.textBold}
              noWrap={true}
            >
              Data {format(new Date(value.dateOrigin), "dd/MM/yyyy")} -{" "}
              {format(new Date(value.dateDestination), "dd/MM/yyyy")}
            </Typography>
          );
        }
      },
    })

  }
  return arranjo
};
