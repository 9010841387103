import { Button } from "@material-ui/core";
import React from 'react';
import { useHistory } from 'react-router';

//styles
import useStyles from './styles';
import { handleAmplitudeEvent } from "services/amplitude";

function BackButton() {
  const classes = useStyles();
  const history = useHistory()
  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      className={classes.btnBack}
      onClick={() => {
        handleAmplitudeEvent('Backward Button Clicked')
        history.goBack() 
      }}
      data-cy="buttonVoltar" 
    >
      Voltar
    </Button>
  )
}

export default BackButton;