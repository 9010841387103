import React from "react";
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ZoomIn } from "@material-ui/icons";
import RecalculationStatus from "components/RecalculationStatus";

import makeStyles from "./styles";

export const makeColumns = ({ showRecalculation }) => {
  const classes = makeStyles();

  const getValue = (value, withoutValue, dataCyTag) => {
    return value ? (
      <Typography variant="body2" data-cy={dataCyTag}>
        {value}
      </Typography>
    ) : (
      <Typography variant="body2" className={classes.textInfo} data-cy={dataCyTag}>
        {withoutValue}
      </Typography>
    );
  };

  return [
    {
      name: "process_date",
      label: "Data/hora",
      options: {
        customBodyRender: (value) => getValue(value, "Sem dados", "thDataHora"),
      },
    },
    {
      name: "user",
      label: "Feito por usuário",
      options: {
        customBodyRender: (value) => getValue(value,"Sem usuário", "thFeitoPorUsuario")
      }
    },
    {
      name: "period",
      label: "Período do recálculo",
      options: {
        customBodyRender: (value) => getValue(value, "Sem período", "thPeriodoRecalculo"),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => (
          <div data-cy="divStatusRecalculo">
            <RecalculationStatus value={value} />
          </div>
        ),
      },
    },
    {
      name: "recalcId",
      label: "Ações",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid container direction="column" alignItems="center">
              <Tooltip title="Visualizar Recálculo" aria-label="visualizar-recalculo">
                <IconButton
                  onClick={() => showRecalculation(value)}
                  aria-label="visualizar-recalculo"
                  data-cy="buttonVisualizarRecalculo"
                >
                  <ZoomIn />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        },
      },
    },
  ];
};
