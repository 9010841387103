import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, Paper, Typography, List, ListItem } from "@material-ui/core";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import { Skeleton } from "@material-ui/lab";
import { format } from "date-fns";
import { useAppSelector } from "redux/store";
import { convertDateBr } from "helpers/dates";

import { infoRpmRange } from "helpers/fleetChart";
import { returnNA } from "helpers/returnNA";
import { findValueByKey } from "helpers/functions";

import TooltipCustom from "componentsNew/atoms/tooltip/tooltip";
import HeaderGraphic from "componentsNew/molecules/headerGraphic/headerGraphic";
import PopoverInfo from "componentsNew/atoms/popoverInfo/popoverInfo";
import FullChart from "components/FullChart";
import TooltipChart from "components/TooltipChart";
import TooltipIcon from "images/Tooltip.png";

// styles
import useStyles from "./styles";

const formatDate = "dd/MM/yyyy HH:mm";

//itens popover info
const itens = [
  "Variações de inclinação ",
  "Uso do acelerador",
  "Velocidade em determinados trechos",
  "Tempo de motor ocioso",
];

export default function FleetChart(props) {
  const classes = useStyles();
  const { filters } = useAppSelector((state) => state.vehicleDashboard);
  const [header, setHeader] = useState({ vehicle: "", date: "" });
  const { chartId, data, tooltip, margin, seconds, loading } = props;
  const { rpmrange, series } = data;
  const [tooltipData, setTootipData] = useState({
    date: "",
    rpm: "",
    altitude: "",
    fuel: "",
    speed: "",
    imei: ""
  });

  const handleTooltipData = useCallback((data) => {
    setTootipData(data);
  }, []);

  useEffect(() => {
    if (filters && (filters?.vehicle?.length > 0 || filters?.vehicle?.id)) {

      const date = `${convertDateBr(filters.selectedDates.initialDate)} - ${convertDateBr(filters.selectedDates.finalDate)}`;
      const driverName = findValueByKey(filters, 'driverName');
      const vehicleIdentification = findValueByKey(filters, 'identification');

      let vehicle = returnNA(vehicleIdentification);
      if (driverName) {
        vehicle += ` - ${driverName}`;
      }

      setHeader({ vehicle, date });
    }
  }, []);

  return loading ? (
    <Skeleton
      variant="rect"
      width={"99%"}
      height={"40dvh"}
      style={{ borderRadius: "8px", margin: "2px", minWidth: 100 }}
    />
  ) : (
    <Grid container spacing={2}>
      {(tooltip ?? true) && (
        <Grid
          container
          justifyContent="space-between"
          style={{ marginLeft: 32, marginRight: 16, marginTop: margin ?? 0 }}
        >
          <Grid item style={{ marginTop: 36 }}>
            <HeaderGraphic vehicle={header.vehicle || props?.header?.vehicle} date={header.date || props?.header?.date} />
          </Grid>
          <Grid item style={{ marginTop: 36 }}>
            <PopoverInfo title={"Clique e saiba como utilizar o gráfico"}>
              <span
                style={{
                  fontSize: 16,
                  letterSpacing: "-1%",
                  fontWeight: 400,
                  marginBottom: 5,
                  color: "#595959",
                }}
              >
                Utilize o gráfico para analisar trechos curtos, entender o
                comportamento do motorista em:
              </span>
              {itens.map((o, i) => (
                <span key={`item-${i}`}
                  style={{
                    fontSize: 16,
                    letterSpacing: "-1%",
                    fontWeight: 500,
                    color: "#181814",
                  }}
                >
                  {i + 1} - {o}
                </span>
              ))}
              <span
                style={{
                  fontSize: 16,
                  letterSpacing: "-1%",
                  fontWeight: 400,
                  marginTop: 24,
                  color: "#595959",
                }}
              >
                Tudo isso, informando o horário/data auxiliando por exemplo na
                gestão de abastecimento ou em outras situações onde poderá usar
                as informações disponibilizadas.
              </span>
            </PopoverInfo>
          </Grid>
        </Grid>
      )}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Paper elevation={0} className={classes.root}>
          <Grid container spacing={2}>
            {series && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container alignContent="center" justifyContent="center">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FullChart
                    series={series}
                    getTooltipData={handleTooltipData}
                    data={data}
                    seconds={seconds}
                    chartId={chartId}
                  />
                </Grid>
              </Grid>
            </Grid>}

            {!tooltip && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TooltipChart data={tooltipData} />
            </Grid>
            }
          </Grid>
        </Paper>
      </Grid>
      <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
        {rpmrange && Object.entries(rpmrange).length > 0 && (tooltip ?? true) && (
          <Paper elevation={0} className={classes.legend}>
            <Typography className={classes.legendTitle}>
              Abaixo um guia das faixas de RPM
            </Typography>
            <List>
              {Object.entries(rpmrange).map((range) => {
                let rpmStart = range[1][0];
                let rpmEnd = range[1][1];
                let tooltipTitle = range[1][2];
                let tooltipDescription = range[1][3];
                return (
                  <ListItem key={range[0]} className={classes.listItem}>
                    <Brightness1Icon
                      style={{ color: infoRpmRange[range[0]].color }}
                      className={classes.listItemIcon}
                    />
                    <Typography
                      variant="caption"
                      className={classes.listItemTitle}
                    >
                      {infoRpmRange[range[0]].title}
                    </Typography>
                    <div>
                      <Typography
                        variant="caption"
                        className={classes.listItemSubTitle}
                      >
                        {rpmStart} - {rpmEnd}
                      </Typography>
                      <Typography
                        variant="caption"
                        className={classes.listItemSubTitle2}
                      >
                        RPM
                      </Typography>
                      <TooltipCustom
                        title={
                          <div>
                            <div
                              style={{
                                paddingBottom: 10,
                                display: "flex",
                                alignItems: "center",
                                alignSelf: "center",
                              }}
                            >
                              <img
                                src={TooltipIcon}
                                alt="Tooltip Gobrax"
                                data-cy="TooltipGobrax"
                              />
                              <span
                                style={{
                                  marginLeft: 10,
                                  fontSize: 16,
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                {tooltipTitle}
                              </span>
                            </div>
                            <span style={{ fontWeight: 400, fontSize: 16 }}>
                              {tooltipDescription}
                            </span>
                          </div>
                        }
                      >
                        <IconButton
                          onClick={() => { }}
                          className={classes.logoIconButton}
                        >
                          <img
                            src={TooltipIcon}
                            alt="Tooltip Gobrax"
                            className={classes.logoTooltip}
                            data-cy="TooltipGobrax"
                          />
                        </IconButton>
                      </TooltipCustom>
                    </div>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        )}
      </Grid>
      {(tooltip ?? true) && series && (
        <Grid item xl={8} lg={8} md={7} sm={12} xs={12}>
          <TooltipChart data={tooltipData} />
        </Grid>
      )}
    </Grid>
  );
}
